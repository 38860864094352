<template>
  <v-container fluid pa-2>
    <v-row no-gutters>
      <v-col cols="12" md="12" sm="12" lg="12" class="px-2">
        <v-row no-gutters>
          <v-spacer></v-spacer>
          <v-col cols="12" md="4" sm="4" lg="4" class="pr-2">
            <v-menu
              v-model="startDate"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  hide-details
                  v-model="startDateValue"
                  label="Start Date"
                  append-icon="event"
                  readonly
                  solo
                  flat
                  outlined
                  dense
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                :show-current="true"
                v-model="startDateValue"
                @input="onDateChange"
                :max="new Date().toISOString().substr(0, 10)"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col md="4" sm="4" lg="4" class="pr-2 align-self-center">
            <v-menu
              v-model="endDate"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  hide-details
                  v-model="endDateValue"
                  label="End Date"
                  append-icon="event"
                  readonly
                  solo
                  flat
                  outlined
                  dense
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="endDateValue"
                @input="onDateChange"
                :max="new Date().toISOString().substr(0, 10)"
                :min="startDateValue"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row no-gutters class="mt-1">
          <v-col cols="12" sm="12" md="12" lg="12" class="mt-1">
            <v-row no-gutters class>
              <v-col cols="8" sm="8" lg="8" md="8" class="px-2">
                <v-card>
                  <v-card-title>
                    Meter Consumption
                    <v-spacer></v-spacer>
                    <v-text-field
                      v-model="meterSearch"
                      append-icon="mdi-magnify"
                      label="Search"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-card-title>
                  <v-data-table
                    :headers="meterConsumtionHeaders"
                    :items="meterData"
                    :search="meterSearch"
                  >
                    <template slot="items" slot-scope="props">
                      <td>{{ props.item.meterId }}</td>
                      <td>{{ props.item.consumption }} Wh</td>
                    </template>
                  </v-data-table>
                </v-card>
                <v-card class="mt-2">
                  <v-card-title>
                    Ref Meter Consumption
                    <v-spacer></v-spacer>
                    <v-text-field
                      v-model="refMeterSearch"
                      append-icon="mdi-magnify"
                      label="Search"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-card-title>
                  <v-data-table
                    :headers="refMeterConsumtionHeaders"
                    :items="refMeterData"
                    :search="refMeterSearch"
                  >
                    <template slot="items" slot-scope="props">
                      <td>{{ props.item.refMeterId }}</td>
                      <td>{{ props.item.consumption }} Wh</td>
                    </template>
                  </v-data-table>
                </v-card>
              </v-col>
              <v-col cols="3">
                <v-card>
                  <v-card-title>
                    <span class="title">Incoming Consumption</span>
                    <v-spacer></v-spacer>
                  </v-card-title>
                  <v-card-text>
                    <v-row no-gutters>
                      <v-col cols="2">
                        <v-icon color="green" style="font-size:60px">data_usage</v-icon>
                      </v-col>
                      <v-col class="ml-4 align-self-center">
                        <span class="display-1 black--text">{{allMeterConsumption}}</span>
                        <span class="subheading">Wh</span>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
                <v-card class="mt-4">
                  <v-card-title>
                    <span class="title">Outgoing Consumption</span>
                    <v-spacer></v-spacer>
                  </v-card-title>
                  <v-card-text>
                    <v-row no-gutters>
                      <v-col cols="2">
                        <v-icon color="orange" style="font-size:60px">data_usage</v-icon>
                      </v-col>
                      <v-col class="ml-4 align-self-center">
                        <span class="display-1 black--text">{{refMeterConsumption}}</span>
                        <span class="subheading">Wh</span>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
                <v-card class="mt-4">
                  <v-card-title>
                    <span class="title">Loss</span>
                    <v-spacer></v-spacer>
                  </v-card-title>
                  <v-card-text>
                    <v-row no-gutters>
                      <v-col cols="2">
                        <v-icon color="red" style="font-size:60px">trending_down</v-icon>
                      </v-col>
                      <v-col class="ml-4 align-self-center">
                        <span class="display-1 black--text">{{lossPercentage}}</span>
                        <span class="subheading">%</span>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import apiHelper from "../apiHelper";
export default {
  components: {},
  data() {
    return {
      startDateValue: null,
      startDate: false,
      endDateValue: null,
      endDate: false,
      startDateMinValue: null,
      meterData: [],
      refMeterData: [],
      meterSearch: "",
      refMeterSearch: "",
      allMeterConsumption: "",
      refMeterConsumption: "",
      lossPercentage: "",
      meterConsumtionHeaders: [
        {
          text: "Serial No",
          align: "center",
          value: "meterId"
        },
        {
          text: "Consumption (Wh)",
          align: "center",
          sortable: false,
          value: "consumption"
        }
      ],
      refMeterConsumtionHeaders: [
        {
          text: "Serial No",
          align: "center",
          value: "refMeterId"
        },
        {
          text: "Consumption  (Wh)",
          align: "center",
          sortable: false,
          value: "consumption"
        }
      ]
    };
  },
  computed: {
    meterSelected() {
      return this.$store.getters["MDMStore/getterSelectedMeter"];
    }
  },
  watch: {},
  mounted() {
    var startDateMinValueRange = new Date();
    startDateMinValueRange.setDate(startDateMinValueRange.getDate() - 10);
    this.startDateMinValue = this.formatDate(
      startDateMinValueRange.toLocaleDateString("en-US", {
        month: "2-digit",
        day: "2-digit",
        year: "numeric"
      })
    );
    this.endDateValue = this.formatDate(
      new Date().toLocaleDateString("en-US", {
        month: "2-digit",
        day: "2-digit",
        year: "numeric"
      })
    );
    var startDateRange = new Date();
    startDateRange.setDate(startDateRange.getDate() - 30);
    this.startDateValue = this.formatDate(
      startDateRange.toLocaleDateString("en-US", {
        month: "2-digit",
        day: "2-digit",
        year: "numeric"
      })
    );
    this.getLossData();
  },
  created() {},
  methods: {
    formatDate(date) {
      if (!date) return null;
      const [month, day, year] = date.split("/");
      return `${year}-${month}-${day}`;
    },
    onDateChange() {
      this.startDate = false;
      this.endDate = false;
      this.getLossData();
    },
    async getLossData() {
      var postData = {
        startDate: this.startDateValue,
        endDate: this.endDateValue,
        orgId: localStorage.getItem("orgId")
      };
      var lossData = await apiHelper("POST", "mdm/api/getLossData", postData);
      this.meterData = lossData.data.meterData;
      this.allMeterConsumption = this.meterData
        .reduce(function(accumulator, currentValue) {
          return accumulator + Number(currentValue.consumption);
        }, 0)
        .toFixed(2);
      this.refMeterData = lossData.data.refMeterData;
      this.refMeterConsumption = this.refMeterData
        .reduce(function(accumulator, currentValue) {
          return accumulator + Number(currentValue.consumption);
        }, 0)
        .toFixed(2);
      var lossValue = (
        ((this.refMeterConsumption - this.allMeterConsumption) * 100) /
        this.refMeterConsumption
      ).toFixed(2);
      this.lossPercentage =
        lossValue == -Infinity || lossValue == "NaN"
          ? 0
          : parseFloat(lossValue);
    }
  }
};
</script>